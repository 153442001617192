import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,5,6,7,8,10,9];

export const dictionary = {
		"/": [~12],
		"/(app)/admin/kalendarz/[calendarId]": [~14,[2,4,5],[3]],
		"/(app)/admin/kalendarz/[calendarId]/pricing": [15,[2,4,5],[3]],
		"/(app)/admin/kalendarz/[calendarId]/settings": [16,[2,4,5],[3]],
		"/(app)/admin/kalendarz/[calendarId]/widget": [17,[2,4,5],[3]],
		"/(app)/admin/obiekt/[venueId]": [~18,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/calendars": [~19,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/calendars/new": [~20,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/holidays": [~21,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/metody-platnosci": [~22,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/photos": [23,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/promote": [24,[2,4,6],[3]],
		"/(app)/admin/obiekt/[venueId]/settings": [25,[2,4,6],[3]],
		"/(app)/admin/organizacja/[organizationId]": [26,[2,4,7],[3]],
		"/(app)/admin/organizacja/[organizationId]/instytucje-partnerskie": [~27,[2,4,7],[3]],
		"/(app)/admin/organizacja/[organizationId]/instytucje-partnerskie/[associationId]": [~28,[2,4,7],[3]],
		"/(app)/admin/organizacja/[organizationId]/metody-platnosci": [29,[2,4,7],[3]],
		"/(app)/admin/organizacja/[organizationId]/przelewy-online": [~30,[2,4,7],[3]],
		"/(app)/admin/organizacja/[organizationId]/ustawienia": [31,[2,4,7],[3]],
		"/dla-biznesu": [~59,[11]],
		"/dla-biznesu/cennik": [~60,[11]],
		"/dla-biznesu/dla-kogo": [~61,[11]],
		"/dla-biznesu/funkcjonalnosci-systemu": [~62,[11]],
		"/dla-biznesu/orlik-2012": [~63,[11]],
		"/(widget)/drukuj/[calendarId]": [~53],
		"/(app)/kontakt": [~32,[2],[3]],
		"/(app)/moje-konto": [33,[2,8],[3]],
		"/(app)/moje-konto/rezerwacje": [~34,[2,8],[3]],
		"/(app)/obiekt/new/[[step]]": [36,[2],[3]],
		"/(app)/obiekt/[venueId]": [~35,[2],[3]],
		"/(app)/polityka-prywatnosci": [~37,[2],[3]],
		"/(app)/regulamin": [~38,[2],[3]],
		"/(widget)/rezerwujesz/[calendarId]": [~54,[10]],
		"/(widget)/rezerwujesz/[calendarId]/confirm": [55,[10]],
		"/(widget)/rezerwujesz/[calendarId]/status": [~56,[10]],
		"/(app)/super-admin": [39,[2,9],[3]],
		"/(app)/super-admin/grupy-obiektow": [~40,[2,9],[3]],
		"/(app)/super-admin/importuj-z-google": [41,[2,9],[3]],
		"/(app)/super-admin/merchanci": [~42,[2,9],[3]],
		"/(app)/super-admin/obiekty": [~43,[2,9],[3]],
		"/(app)/super-admin/organizacje": [~44,[2,9],[3]],
		"/(app)/super-admin/qr-code": [45,[2,9],[3]],
		"/(app)/super-admin/rezerwacje": [~46,[2,9],[3]],
		"/(app)/super-admin/system-stats": [47,[2,9],[3]],
		"/(app)/super-admin/uzytkownicy": [~48,[2,9],[3]],
		"/(app)/super-admin/webhooks": [~49,[2,9],[3]],
		"/(app)/super-admin/wiadomosci": [~50,[2,9],[3]],
		"/(widget)/widget/[calendarId]": [~57],
		"/(widget)/widget/[calendarId]/widget-placeholder": [~58],
		"/(app)/znajdz-obiekt": [~51,[2],[3]],
		"/(app)/znajdz-obiekt/[...restVenueParam=restVenueParam]": [~52,[2],[3]],
		"/(app)/[slug]": [~13,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';